import React, { useState } from "react";
import Header from "./Header";
import { Sidebar } from "./Sidebar";
import PolicyCreation from "./Monitor/PolicyCreation";
import { Dashboard } from "./Monitor/Dashboard";
import { Monitor } from "./Monitor/Monitor";
import Pricing from "./Pricing/Pricing";
import Explore from "./Explore/Explore";

export const MainPage = (props) => {
  const [menu, setMenu] = useState("Explore");
  const [selectedPolicyNo, setselectedPolicyNo] = useState("");
  const [selectedPolicymasterHolder, setselectedPolicymasterHolder] = useState("");
  const handleMenuClick = (selectedMenu) => {
    setMenu(selectedMenu);
  };
  return (
    <>
      <Header email={props.email} setEmail={props.setEmail} />
      <div style={{ marginTop: "45px" }}>
        <Sidebar setMenu={handleMenuClick} menu={menu} />
      </div>

      <>
        <div style={{ overflow: "hidden" }}>
          <Sidebar setMenu={handleMenuClick} menu={menu} />
          {menu === "Explore" ? (
            <Explore />
          ) : menu === "Pricing" ? (
            <Pricing />
          ) : menu === "Monitor" ? (
            <Dashboard setMenu={handleMenuClick} menu={menu} 
            selectedPolicyNo={selectedPolicyNo} 
            setselectedPolicyNo={setselectedPolicyNo}
            setselectedPolicymasterHolder={setselectedPolicymasterHolder} />
          ) : menu === "Policy" ? (
            <PolicyCreation setMenu={handleMenuClick} />
          ) : menu === "Monitor Details" ? (
            <Monitor setMenu={handleMenuClick}
            selectedPolicyNo={selectedPolicyNo}
            selectedPolicymasterHolder={selectedPolicymasterHolder}/>
          ) : (
            ""
          )}
        </div>
      </>
    </>
  );
};
